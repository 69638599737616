import bearer from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'

export default {
    auth: bearer,
    http: axios,
    router: router,
    tokenDefaultName: 'auth_token',
    tokenStore: ['localStorage'],
    rolesVar: 'role',
    loginData: {url: `auth/login`, method: 'POST', redirect: '/home', fetchUser: true},
    logoutData: {url: `auth/logout`, method: 'POST', redirect: '/', makeRequest: true},
    fetchData: {url: `auth/user`, method: 'GET', enabled: true},
    refreshData: {url: `auth/refresh`, method: 'GET', enabled: true, interval: 30}
};
